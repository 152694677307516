import {mapState} from "vuex"

export default {
  data: () => ({
    candidateColorID: null,
  }),

  computed: {
    ...mapState('crmCandidateColors', {
      colors: state => state.colors
    }),
    candidateColor() {
      if (this.candidateColorID) {
        const color = this.colors.find(color => color.id === this.candidateColorID)
        return color && color.hex_color_code ? color.hex_color_code : ''
      } else {
        return ''
      }
    },
  },

  mounted() {
    this.setColorId(this.item.color)
  },


  methods: {
    setColorId(colorId) {
      this.candidateColorID = colorId;
    },
  },
  
  watch: {
    'item.color'(value) {
      this.setColorId(value)
    }
  },
}