<script>
import { isMobile } from '@/util'
import DoubleClickMixin from "@/mixins/doubleClick.mixin"
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin, DoubleClickMixin],
  inject: ['svc'],

  render() {
    return this.$scopedSlots.default({
      text: this.text,
      onDoubleClick: this.doubleClick,
      update: this.updateIgnoreResults
    })
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    ID: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    isMobile: false
  }),

  computed: {
    text() {
      if(this.value) return 'Ignored'
      return 'Included'
    },
  },


  mounted() {

  },

  methods: {
    checkOnMobile() {
      this.isMobile = isMobile()
    },

    doubleClick() {
      if(this.isMobile) return
      this.onDoubleClick(this.updateIgnoreResults)
    },

    async updateIgnoreResults() {
      try {
        await this.svc.updateIgnoreResults(this.ID, !this.value)
        this.$notify({type: 'success', text: 'Updated'})

      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
        });
      }

    },

    onTouchStart() {
      if(!this.isMobile) return
      this.touchStart(this.updateIgnoreResults())
    },

    onTouchEnd() {
      if(!this.isMobile) return
      this.touchEnd()
    }
  }
}
</script>
