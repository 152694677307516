<template lang="pug">
  tr(
    data-test="results-row"
    :style="{'background-color': candidateColor}"
  ).res-row
    td.text-center {{ positionInList }}
      br
      svcToFuncProvider
        candidate-color(:activeItems="[item]" :page="COLOR_PAGE")
    td(data-test="result-datetime").time  {{ item.formattedDatetime }}
      br
      | {{ item.location | clipString(12) }}
    td.name.text-center
      span {{ item.candidate.name }}
      div {{ item.candidate.phone }}
    showing-provider(v-slot="showingProvider")
      v-dialog(
        hide-overlay
        max-width="500px"
        transition="dialog-bottom-transition"
        :value="showingProvider.showing"
        @input="showingProvider.updateShowing"
      )
        template(v-slot:activator="{on}")
          td.number.pointer(v-on="on") {{ item.reservationCode }}
        v-card
          v-card-title Candidate log
          v-card-text
            logs(:ID="item.candidate.ID" :key="'showingProvider.showing' + showingProvider.showing" )
          v-card-actions
            v-spacer
            v-btn(text color="success" @click="showingProvider.toggle") close
    td(:class="{ 'status-init': statusInit, 'loaded-not-seen': loadedNotSeen, 'loaded-seen': loadedSeen}" :style="{backgroundColor: colorStatus}").res-info
      result-info(v-if="!item.is_mijn_reservation" data-test="results-info" :statusInit="statusInit" :model="item" :loadedNotSeen="loadedNotSeen" :loadedSeen="loadedSeen")
      edit-result-info(v-if="item.is_mijn_reservation" data-test="edit-results-info" :statusInit="statusInit" :model="item" :loadedNotSeen="loadedNotSeen" :loadedSeen="loadedSeen")
    td.res-status
      v-chip(v-if="item.result" :color="statusChipColor" small dark) {{ item.result.status }}
      v-chip(v-else color="black" small dark) Not fetched
    td
      v-checkbox(
        v-model="item.send_result_emails"
        :key="item.ID"
        @change="updateSendResults"
        hide-details
        color="green"
      ).justify-center.mt-0.checkbox-centered
    td
      inform-candidate(
        :ID="item.ID"
        :messageSentCount="item.message_sent_count"
      )
    td.text-center
      willCome(:will_come_to_course="item.will_come_to_course" @update="updateCandidateWillCome")
    td.app-id {{ item.applicationID }}
    td.type
      v-chip(small :color="PURPLE" v-if="item.is_mijn_reservation").text-bold.white--text Mijn
      v-chip(small color="amber lighten-1" v-else).text-bold.white--text Top
    td.product
      v-chip(small color="amber lighten-1").text-bold.white--text {{ item.productName }}
    td.notes
      notes(:value="item.notes" :ID="item.ID" :service="svc")
</template>

<script>
import { RESULT_STATUS } from '../core/models/resultReservedExam'
import { COLOR_STATUS, PURPLE } from '@/app/admin/modules/results/core/results-const'
import Notes from "@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue"
import { COLOR_PAGE } from '@/util/const'
import ColorMixin from "@/mixins/color.mixin"
import IgnoreResults from "./ResultsIgnore.vue"
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [ColorMixin, errorsMixin],

  props: {
    item: {
      required: true,
      type: Object
    },
    index: Number,
    primaryPagination: Object,
    select: Function,
    isSelected: Boolean
  },

  inject: ['svc'],

  data: () => ({
    PURPLE,
    RESULT_STATUS: RESULT_STATUS,
    COLOR_PAGE: COLOR_PAGE.EXAMS
  }),

  computed: {
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size;
    },

    statusInit() {
      if (!this.item.result || this.item.result.status !== RESULT_STATUS.LOADED) return true
      return false
    },

    failed() {
      if (this.item.result && this.item.result.status === RESULT_STATUS.FAILED) return true
      return false
    },

    loadedNotSeen() {
      if (!this.item.result) return false
      if (this.item.result.status === RESULT_STATUS.LOADED && !this.item.result.seen) return true
      return false
    },

    loadedSeen() {
      if (!this.item.result) return false
      if (this.item.result.status === RESULT_STATUS.LOADED && this.item.result.seen) return true
      return false
    },

    colorStatus() {
      if (this.item.ignore_results) return COLOR_STATUS.IGNORE_RESUTLS
      if (!this.loadedSeen) return null
      if (this.item.result.has_zero_mistakes) return COLOR_STATUS.ZERO_MISTAKES
      if (this.item.result.has_passed) return COLOR_STATUS.HAS_PASSED
      if (this.item.result.has_not_came) return COLOR_STATUS.HAS_NOT_CAME
      if (!this.item.result.has_passed) return COLOR_STATUS.NOT_HAS_PASSED
      return null
    },

    statusChipColor() {
      if (!this.item.result) return 'black'
      if (this.item.result.status === RESULT_STATUS.LOADED) return 'success'
      if (this.item.result.status === RESULT_STATUS.FAILED) return 'error'
      if (this.item.result.status === RESULT_STATUS.FETCHING) return 'warning'
      return 'black'
    }
  },

  methods: {
    async updateSendResults(val) {
      try {
        this.loading = true
        await this.svc.updateSendResults(this.item.ID, val)
        this.$notify({type: 'success', text: 'Updated'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async updateCandidateWillCome(value) {
      this.$emit('update:willCome', {ID: this.item.ID, value})
    },
  },

  components: {
    resultInfo: () => import ("./ResultsInfo.vue"),
    editResultInfo: () => import ("./EditResultsInfo.vue"),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    svcToFuncProvider: () => import('@/components/global/SvcProviderToFunc.vue'),
    logs: () => import('./ResultsCandidateLog.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
    informCandidate: () => import('./ResultsInformCandidate'),
    willCome: () => import('@/app/admin/modules/course_managment/components/СMWillCome.vue'),
    Notes,
    IgnoreResults
  }
}
</script>

<style lang="scss" scoped>
.res-row {
  transition: background-color .3s;
}

.loaded-not-seen {
  background-color: #ccc;
}

.time {
  width: 10%;
  text-align: center;
}

.app-id {
  text-align: center;
}

.number {
  width: 10%;
  text-align: center;
}

.product {
  width: 10%;
  text-align: center;
}

.type {
  text-align: center;
}

.res-info {
  width: 10%;
  text-align: center;
}

.res-status {
  width: 10%;
  text-align: center;
}

.name {
  min-width: 20%;
  width: 20%;
}

.notes {
  max-width: 100px;
}


.note {
  overflow: hidden;
  max-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  margin-bottom: 0;
  max-width: 150px;
}

</style>

<style lang="scss">
.checkbox-centered {
  & .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
}
</style>
