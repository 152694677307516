export default {
  data: () => ({
    click: undefined,
  }),

  methods: {
    onDoubleClick (callback) {
      return new Promise ((resolve, reject) => {
        if (this.click) {
          clearTimeout(this.click)
          callback()
        }
        this.click = setTimeout(() => {
          this.click = undefined
        }, 200)
      })
    }
  }
}